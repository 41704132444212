<template>
  <div>
    
  </div>
</template>

<script lang="js">


export default {
  name: 'Home',
  components: {
  },
  computed: {
    theme() {
        return this.$store.getters['Theme/theme']
    },
  }
}
</script>
